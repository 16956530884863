import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['quantity', 'total']
  static values = {
    price: Number,
    currency: String
  }

  connect() {
    this.currencyValue = this.data.get('currency');
    this.updateProductTotal();
  }

  updateProductTotal() {
    const quantity = this.quantityTarget.value;
    this.totalTarget.innerHTML = `${(quantity * this.priceValue)} ${this.currencyValue}`
    const event = new CustomEvent('total-updated');
    document.dispatchEvent(event)
  }

  increment(event) {
    event.preventDefault();
    this.quantityTarget.value = (this.quantityTarget.value * 1) + 1;
    this.updateProductTotal();
  }

  decrement(event) {
    event.preventDefault();
    let value = this.quantityTarget.value
    if(value < 2) {
      value = 2;
    };

    this.quantityTarget.value = (value * 1) - 1;
    this.updateProductTotal();
  }
}
