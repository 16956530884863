import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['total', 'summary', 'remain']
  static values = {
    currency: String,
    freeDelivery: Number,
    isFreeDelivery: Boolean
  }

  connect() {
    this.isFreeDelivery = false;
  }

  updateTotal() {
    let price = 0

    this.totalTargets.forEach((totalTarget) => {
      const itemPrice = parseInt(totalTarget.innerHTML, 10)
      price += itemPrice
    })

    let remain = this.freeDeliveryValue - price
    let remainAmount = remain < 0  ? 0 : remain;

    this.remainTarget.innerHTML  = `${remainAmount} ${this.currencyValue}`;
    this.summaryTarget.innerHTML =  `${price} ${this.currencyValue}`;
  }
}
